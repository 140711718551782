import { useQuery } from '@apollo/client';
import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Header } from 'tabler-react';
import useGetCurrentUserType from '../../modules/common/hooks/useGetCurrentUserType'
import { GET_USER_MERITS_BY_USER_ID } from '../../graphql/GET_USER_MERITS_BY_STUDENT_ID';
import { useRootStore } from '../../hooks';
import { EUserTypes } from '../User/userTypes';
import { EUSPATitles, isLifetimeMemberTemplate } from '../constants';
import { Loading } from 'components/Loading';

interface IStudentMeritsButtonProps {
  studentId: string;
}


const StudentMeritsButton = ({ studentId }: IStudentMeritsButtonProps) => {
  const { data, loading, error } = useQuery(
    GET_USER_MERITS_BY_USER_ID,
    {
      variables: {
        userId: studentId,
        userType: 'student'
      }
    }
  );

  if (loading) {
    return <Loading />
  }

  if (error) {
    console.log(error)
    return <p>Error: {error.message}</p>
  }

  return <StudentMeritsButtonInner merits={[...data?.userMerits ?? []]}/>
}

const StudentMeritsButtonInner = ({ merits }) => {
  const { currentUser } = useRootStore();
  const { isAdmin } = useGetCurrentUserType()
  const hasLifetimeMembership = merits.some(
    (item) => isLifetimeMemberTemplate(item)
  );

  const memberLicense = useMemo(() => {
    if (hasLifetimeMembership) {
      return merits.find(
        (item) => item.template_title === 'Lifetime Member'
      );
    }

    return merits.find(
      (item) => item.template_title === EUSPATitles.Member
    );
  }, [hasLifetimeMembership, merits]);

  const isMemberLicenseExpired = useMemo(
    () =>
      memberLicense?.merits_uspa_member_expiration &&
      new Date(memberLicense.merits_uspa_member_expiration) < new Date(),
    [memberLicense]
  );

  const userMerits = useMemo(
    () =>
      merits
        .filter((item) =>
          ['A-License', 'B-License', 'C-License', 'D-License'].includes(
            item.template_title
          )
        )
        .sort((a, b) => a.template_title.localeCompare(b.template_title)),
    [merits]
  );

  const isLicenseExpired = useMemo(() => {
    if (hasLifetimeMembership) {
      return false;
    }

    if (userMerits?.[0]?.merits_uspa_member_expiration > Date.now()) {
      return false;
    }

    if (
      new Date(merits[0]?.merits_uspa_member_expiration).getTime() >
      Date.now()
    ) {
      return false;
    }

    if (!isMemberLicenseExpired) {
      return false;
    }

    return true;
  }, [

    userMerits,
    isMemberLicenseExpired,
    merits,
    hasLifetimeMembership
  ]);

  //MERITS
  const [meritConfig, setMeritConfig] = useState(null);

  const queryServer = async (url: string) => {
    try {
      const response = await axios.post(url);

      setMeritConfig(response.data);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  const current = useCallback(() => {
    queryServer('/auth/merit/current');

  }, []);

  useEffect(() => {
    current();
  }, [current]);

  const [submitted, setSubmitted] = useState(false);

  // Reload on screen focus
  useEffect(() => {
    const handleWindowFocus = () => {
      // Call your function here
      if (submitted) {
        current();
      }
    };

    document.addEventListener('focus', handleWindowFocus);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener('focus', handleWindowFocus);
    };
  }, [submitted]);

  const MeritsSyncButton = () => {
    const location = useLocation();
    const url = `/auth/merit/sync?redirect=${location.pathname}`

    return (
      <>
          <a href={url} className="d-block">
          <Button
            block
            icon="refresh-cw"
            color="secondary"
            className="d-block"
            disabled={isAdmin}
          >
            USPA (Merits)
          </Button>
          </a>
      </>
    )
  }

  return (
    <>
      {userMerits?.length ? (
        <Header.H5 className="mt-3">
          <span
            className={`status-icon ${
              isLicenseExpired ? 'bg-danger' : 'bg-success'
            }`}
          />{' '}
          {userMerits?.length > 0
            ? userMerits[userMerits.length - 1].template_title
            : userMerits?.length > 0
            ? userMerits[userMerits.length - 1].template_title
            : ''}
        </Header.H5>
      ) : (
        currentUser.type === EUserTypes.student && (
          <MeritsSyncButton />
        )
      )}
    </>
  );
};

export default StudentMeritsButton;
